<template>
  <v-card>
    <v-card-text>
      <Substipulator
        :contractId="$route.params.id"
        @sub="subSelected($event)"
        v-if="hasSub"
      />

      <v-form ref="form" lazy-validation v-model="valid" @submit.prevent="save">
        <v-row dense>
          <v-col cols="12" xl="4" lg="4" md="4" sm="6">
            <v-autocomplete
              id="lifeCapitalType"
              name="lifeCapitalType"
              label="Tipo capital"
              dense
              outlined
              required
              :rules="[required]"
              :items="[
                'Capital escalonado',
                'Múltiplo salarial',
                'Capital uniforme',
                'Capital global'
              ]"
              :disabled="!!capital.id"
              @change="onChangeCapital"
              v-model="capital.lifeCapitalType"
            />
          </v-col>
          <template v-if="capital.lifeCapitalType === 'Capital escalonado'">
            <v-col
              cols="12"
              xl="8"
              lg="8"
              md="8"
              sm="6"
              class="text-right"
              v-permission="{
                roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
                operator: '==='
              }"
            >
              <BaseButton
                id="btn-add"
                name="btn-add"
                color="secondary"
                title="Escalonado"
                height="40"
                @click="add"
              />
            </v-col>
          </template>
        </v-row>

        <BaseTableList dense>
          <template v-slot:table>
            <v-data-table
              disable-sort
              hide-default-footer
              dense
              item-key="id"
              :headers="headers"
              :items="capital.lifeCapitals"
            >
              <template
                v-slot:[`item.lifeCapitalDescription`]="{ item, index }"
              >
                <v-text-field
                  dense
                  :readonly="contract.situation === 'Cancelado'"
                  :id="`lifeCapitalDescription-${index}`"
                  :name="`lifeCapitalDescription-${index}`"
                  :rules="[required]"
                  v-model="item.lifeCapitalDescription"
                />
              </template>

              <template
                v-slot:[`item.lifeCapitalMinimumCapitalLimit`]="{ item, index }"
              >
                <BaseMoney
                  dense
                  :readonly="contract.situation === 'Cancelado'"
                  :id="`lifeCapitalMinimumCapitalLimit-${index}`"
                  :name="`lifeCapitalMinimumCapitalLimit-${index}`"
                  :outlined="false"
                  :rules="[required]"
                  v-model="item.lifeCapitalMinimumCapitalLimit"
                />
              </template>

              <template
                v-slot:[`item.lifeCapitalMaximumCapitalLimit`]="{ item, index }"
              >
                <BaseMoney
                  dense
                  :readonly="contract.situation === 'Cancelado'"
                  :id="`lifeCapitalMaximumCapitalLimit-${index}`"
                  :name="`lifeCapitalMaximumCapitalLimit-${index}`"
                  :outlined="false"
                  :rules="[required]"
                  v-model="item.lifeCapitalMaximumCapitalLimit"
                />
              </template>

              <template
                v-slot:[`item.lifeCapitalQuantityMultipleSalary`]="{
                  item,
                  index
                }"
              >
                <BaseNumber
                  dense
                  maxlength="3"
                  :readonly="contract.situation === 'Cancelado'"
                  :id="`lifeCapitalQuantityMultipleSalary-${index}`"
                  :name="`lifeCapitalQuantityMultipleSalary-${index}`"
                  :outlined="false"
                  :rules="[required]"
                  v-model="item.lifeCapitalQuantityMultipleSalary"
                />
              </template>

              <template v-slot:[`item.action`]="{ item }">
                <v-tooltip
                  bottom
                  v-if="
                    capital.lifeCapitalType === 'Capital escalonado' &&
                    contract.situation !== 'Cancelado'
                  "
                >
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      color="error"
                      small
                      @click="remove(item)"
                      v-permission="{
                        roles: [
                          'A1',
                          'AC1',
                          'AC2',
                          'AC3',
                          'ACI1',
                          'ACI2',
                          'ACI3'
                        ],
                        operator: '==='
                      }"
                    >
                      fa-regular fa-trash-can
                    </v-icon>
                  </template>
                  <span>Excluir</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </template>
        </BaseTableList>

        <v-divider class="my-5" />

        <div class="d-flex">
          <BaseButton
            outlined
            id="btn-view-history"
            name="btn-view-history"
            color="error darken-1"
            title="Visualizar histórico"
            @click="dialog = !dialog"
            v-if="capital.id && capital.hasReadjustmentHistory"
          />

          <v-spacer />

          <BaseButton
            id="btn-back"
            name="btn-back"
            outlined
            color="primary"
            title="Voltar"
            @click="back"
          />

          <template v-if="contract.situation !== 'Cancelado'">
            <BaseButton
              id="btn-save"
              name="btn-save"
              type="submit"
              color="primary"
              title="Salvar"
              :disabled="!valid"
              v-permission="{
                roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
                operator: '==='
              }"
            />
          </template>
        </div>
      </v-form>
    </v-card-text>

    <DialogCapitalHistory
      v-if="dialog"
      :dialog="dialog"
      :customerId="substipulator ? substipulator : customerId"
      :contractId="$route.params.id"
      :capital="capital"
      @closeModal="closeModal"
    />

    <DialogEffectiveDate
      v-if="dialogEffectiveDate"
      :dialog="dialogEffectiveDate"
      :customerId="substipulator ? substipulator : customerId"
      :contractId="$route.params.id"
      :capital="capital"
      @closeModal="closeModal"
    />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { rulesMixin } from '@/mixins/rules';
import { randomId } from '@/helpers/utils';
import { confirmMessage, showMessage } from '@/helpers/messages';
import { ContractService } from '@/services/api/contracts';

export default {
  mixins: [rulesMixin],

  components: {
    Substipulator: () => import('./commons/substipulator'),
    DialogCapitalHistory: () => import('./dialogs/dialog-capital-history'),
    DialogEffectiveDate: () => import('./dialogs/dialog-capital-effective-date')
  },

  props: {
    productType: {
      type: String
    },
    customerId: {
      type: String
    },
    hasSub: {
      type: Boolean
    }
  },

  data: () => ({
    substipulator: null,
    valid: true,
    headers: [],
    capital: {
      hasReadjustmentHistory: false,
      id: null,
      lifeCapitalType: null,
      lifeCapitals: []
    },
    dialog: false,
    dialogEffectiveDate: false
  }),

  created() {
    this.filteredHeaders();
    this.search();
  },

  computed: {
    ...mapGetters({
      contract: 'contracts/getContractById'
    })
  },

  methods: {
    async search() {
      try {
        const contractService = new ContractService();
        const { status, data } = await contractService.getLifeCapital({
          contractId: this.$route.params.id,
          customerId: this.substipulator ? this.substipulator : this.customerId
        });

        if (status === 200) {
          this.capital = data;
        } else {
          this.capital = {
            id: null,
            hasReadjustmentHistory: false,
            lifeCapitalType: null,
            lifeCapitals: []
          };
          this.$refs.form.resetValidation();
        }

        this.filteredHeaders();
      } catch (error) {
        throw new Error(error);
      }
    },

    back() {
      this.$emit('back');
    },

    onChangeCapital() {
      this.filteredHeaders();

      if (this.capital.lifeCapitalType === 'Capital escalonado') {
        this.capital.lifeCapitals = [];
      } else {
        this.capital.lifeCapitals = [
          {
            lifeCapitalDescription: null,
            lifeCapitalMinimumCapitalLimit: 0,
            lifeCapitalMaximumCapitalLimit: 0,
            lifeCapitalQuantityMultipleSalary: null
          }
        ];
      }
    },

    filteredHeaders() {
      let headers = [
        { text: 'Descrição', value: 'lifeCapitalDescription' },
        { text: 'Limite mínimo', value: 'lifeCapitalMinimumCapitalLimit' },
        { text: 'Limite máximo', value: 'lifeCapitalMaximumCapitalLimit' },
        {
          text: 'Múltiplo salarial',
          value: 'lifeCapitalQuantityMultipleSalary'
        },
        { text: '', value: 'action' }
      ];

      if (this.capital.lifeCapitalType !== 'Múltiplo salarial') {
        headers = headers.filter(
          header => header.value !== 'lifeCapitalQuantityMultipleSalary'
        );
      }

      if (this.capital.lifeCapitalType !== 'Capital escalonado') {
        headers = headers.filter(header => header.value !== 'action');
      }

      this.headers = headers;
    },

    save() {
      if (!this.$refs.form.validate(true)) return;

      if (
        this.capital.lifeCapitalType === 'Capital escalonado' &&
        this.capital.lifeCapitals.length === 0
      ) {
        showMessage(
          'error',
          'Obrigatório adicionar pelo menos 1 registro para esse tipo de capital!'
        );
        return;
      }

      if (this.capital.id) {
        this.dialogEffectiveDate = true;
      } else {
        this.create();
      }
    },

    async create() {
      try {
        let params = Object.assign({}, this.capital);

        params.lifeCapitals.forEach(x => {
          delete x.newItem;
          delete x.id;
        });

        const contractService = new ContractService();
        const { status } = await contractService.createLifeCapital({
          contractId: this.$route.params.id,
          customerId: this.substipulator ? this.substipulator : this.customerId,
          ...params
        });

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.search();
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async remove(item) {
      const result = await confirmMessage(
        'Atenção!',
        'Deseja realmente excluir o registro selecionado?'
      );

      if (!result) return;

      if (item.newItem) {
        const index = this.capital.lifeCapitals.findIndex(
          x => x.id === item.id
        );

        if (index !== -1) {
          this.capital.lifeCapitals.splice(index, 1);
        }
      } else {
        try {
          const contractService = new ContractService();
          const { status } = await contractService.deleteLifeCapital(item.id);

          if (status === 204) {
            showMessage('success', 'Operação realizada com sucesso');
            this.search();
          }
        } catch (error) {
          throw new Error(error);
        }
      }
    },

    add() {
      this.capital.lifeCapitals.push({
        id: randomId(),
        lifeCapitalDescription: null,
        lifeCapitalMinimumCapitalLimit: 0,
        lifeCapitalMaximumCapitalLimit: 0,
        lifeCapitalQuantityMultipleSalary: null,
        newItem: true
      });
    },

    subSelected(event) {
      this.substipulator = event;
      this.search();
    },

    closeModal(search) {
      this.dialog = false;
      this.dialogEffectiveDate = false;

      if (search) {
        this.search();
      }
    }
  }
};
</script>
